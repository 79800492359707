import React from 'react'
import Layout from '../components/layout'

const NoisyPomodoroPrivacyPolicy = () => (
  <Layout>
    <h1>Privacy Policy for Noisy Pomodoro</h1>
    <p>
      At <strong>NiceWork OÜ</strong>, we value your privacy and are committed to protecting your personal data.
      This Privacy Policy outlines how we handle information when you use the <strong>Noisy Pomodoro</strong> app.
    </p>
    <h2>1. Information We Collect</h2>
    <p>
      Noisy Pomodoro does not collect or store any personal information. All data related to the app's
      functionality (e.g., timer settings or noise preferences) is stored locally on your device and
      is not transmitted to any server or third party.
    </p>
    <h2>2. How We Use Your Information</h2>
    <p>
      Since no personal data is collected, we do not use or process any information related to you or your
      device beyond what is necessary for the app to function.
    </p>
    <h2>3. Data Sharing and Disclosure</h2>
    <p>
      We do not share, sell, or disclose any data to third parties. The app operates entirely offline, ensuring
      your preferences and settings remain private.
    </p>
    <h2>4. Third-Party Services</h2>
    <p>The app does not integrate with any third-party services, trackers, or analytics tools.</p>
    <h2>5. Children's Privacy</h2>
    <p>
      The Noisy Pomodoro app is not designed for use by children under the age of 13. We do not knowingly collect
      data from children.
    </p>
    <h2>6. Changes to This Privacy Policy</h2>
    <p>
      We may update this Privacy Policy from time to time. Any changes will be posted within the app or on our
      official website, along with the updated effective date.
    </p>
    <h2>7. Contact Us</h2>
    <p>
      If you have any questions or concerns about this Privacy Policy, please contact us:
    </p>
    <p>
      <strong>NiceWork OÜ</strong> <br />
      Email: <a href="mailto:contact@nicework.company">contact@nicework.company</a>
    </p>
    <h2>Consent</h2>
    <p>
      By using Noisy Pomodoro, you consent to this Privacy Policy and its terms.
    </p>
  </Layout>
)

export default NoisyPomodoroPrivacyPolicy
